var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"grid bg-gray-200 border rounded-lg overflow-hidden overflow-x-auto border-b-0 mb-6",attrs:{"id":"reporting-table-grid"},on:{"mouseenter":function($event){_vm.hoveredTable = true},"mouseleave":function($event){_vm.hoveredTable = null}}},[_c('div',{staticClass:"grid grid-cols-7 text-center gap-1px",staticStyle:{}},_vm._l((_vm.headers),function(header,index){return _c('div',{key:header,staticClass:"bg-white p-4 pb-0 text-xs uppercase font-mono text-gray-400",class:index === 0 ? 'sticky left-0 z-20' : ''},[_vm._v(" "+_vm._s(header)+" ")])}),0),_vm._l((_vm.analytics.coreData),function(row){return _c('div',{key:row.label,staticClass:"grid grid-cols-7 gap-1px bg-gray-200 border-b group",staticStyle:{},on:{"mouseenter":function($event){return _vm.setHoveredRow(row.label)},"mouseleave":function($event){return _vm.setHoveredRow()}}},[_c('div',{staticClass:"bg-white p-2 px-2 sticky left-0 z-20 quick-transition space-x-2 leading-none text-xs flex items-center uppercase font-mono",class:[
        _vm.hoveredRow === row.label ? 'bg-gray-100 text-gray-600' : 'bg-white',
        _vm.hoveredTable && _vm.hoveredRow !== row.label && _vm.hoveredRow
          ? 'text-gray-200'
          : 'text-gray-400',
        _vm.isFavourite(row.key)
          ? _vm.hoveredTable && _vm.hoveredRow !== row.label && _vm.hoveredRow
            ? 'bg-white'
            : 'font-medium bg-yellow-50'
          : ''
      ]},[_c('div',{staticClass:"text-lg cursor-pointer hover:text-yellow-500",class:_vm.isFavourite(row.key)
            ? _vm.hoveredTable && _vm.hoveredRow !== row.label && _vm.hoveredRow
              ? 'text-gray-200'
              : ' text-yellow-500'
            : '',on:{"click":function($event){return _vm.toggleFavourite(row.key)}}},[_c('font-awesome-icon',{attrs:{"icon":[_vm.isFavourite(row.key) ? 'fad' : 'fal', 'star']}})],1),_c('div',{staticClass:"flex-grow truncate",class:_vm.isFavourite(row.key)
            ? _vm.hoveredTable && _vm.hoveredRow !== row.label && _vm.hoveredRow
              ? 'text-gray-200'
              : 'text-gray-900'
            : ''},[_vm._v(" "+_vm._s(row.label)+" ")]),_c('div',{attrs:{"data-tippy-content":_vm.getHelper(row.key)}},[_c('font-awesome-icon',{staticClass:"text-gray-300 text-lg tippy",attrs:{"fixed-width":"","icon":['fal', 'circle-info']}})],1)]),_vm._l((row.data),function(data,index){return _c('div',{key:`${row.label}-${index}`,staticClass:"text-center quick-transition relative flex min-w-max items-center justify-center bg-white p-3",class:[
        _vm.hoveredRow === row.label ? 'bg-gray-100' : 'bg-white',
        _vm.hoveredTable && _vm.hoveredRow !== row.label && _vm.hoveredRow
          ? 'text-gray-200'
          : '',
        _vm.isFavourite(row.key)
          ? _vm.hoveredTable && _vm.hoveredRow !== row.label && _vm.hoveredRow
            ? 'bg-white'
            : 'font-medium bg-yellow-50'
          : ''
      ]},[_vm._v(" "+_vm._s(data.value.toLocaleString())),(row.suffix)?_c('span',[_vm._v(_vm._s(row.suffix))]):_vm._e(),(data.change !== 0 && _vm.checkNumber(data.change))?_c('div',{staticClass:"absolute quick-transition text-xs font-mono -left-7 skew-x-2 transform w-14 bg-white rounded px-1 p-1 flex items-center justify-center leading-none",class:[
          data.positive
            ? 'bg-green-100 text-green-500'
            : 'bg-red-100 text-red-500',
          _vm.hoveredTable && _vm.hoveredRow !== row.label && _vm.hoveredRow
            ? 'opacity-0'
            : ''
        ]},[_vm._v(" "+_vm._s(Math.abs(data.change))+"% ")]):_vm._e()])})],2)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }