<template>
  <div class="h-full">
    <div class="px-6">
      <div class="text-xl font-semibold pt-5 flex items-center space-x-4">
        <div class="flex-grow">Reporting</div>
        <div
          class="text-gray-400 font-normal text-lg uppercase flex items-center space-x-1"
        >
          <div>{{ compareMonth }}</div>
          <div class="text-base">&rarr;</div>
          <div>{{ lastMonth }}</div>
        </div>
      </div>
      <div
        class="text-gray-400 pb-4 pt-2 text-sm items-center italic mb-1.5 flex space-x-1"
      >
        <font-awesome-icon
          fixed-width
          class="text-sm"
          :icon="['fal', 'circle-info']"
        />
        <div>
          Based on stats from {{ compareMonth }} compared to {{ lastMonth }}
        </div>
      </div>
    </div>
    <div class="px-6">
      <div v-if="isAdmin && !isCustomerService" class="grid grid-cols-4 gap-4">
        <router-link
          :to="{ name: 'monthly-stock' }"
          class="col-span-1 border rounded-lg flex items-center space-x-4 p-4 hover:bg-gray-100 cursor-pointer group"
        >
          <div class="text-gray-600 w-20 flex items-center justify-center">
            <font-awesome-icon :icon="['fal', 'table']" class="text-3xl" />
          </div>
          <div>
            <div
              class="text-lg font-semibold col-span-full flex items-center space-x-2"
            >
              <div>Monthly Stock Counts</div>
              <div
                class="transform default-transition group-hover:translate-x-3"
              >
                &rarr;
              </div>
            </div>
            <div class="text-gray-700">
              Generate a report to show the levels of stock for a given date of
              the month
            </div>
          </div>
        </router-link>
        <router-link
          :to="{ name: 'taster-stats' }"
          class="col-span-1 border rounded-lg flex items-center space-x-4 p-4 hover:bg-gray-100 cursor-pointer group"
        >
          <div class="text-gray-600 w-20 flex items-center justify-center">
            <font-awesome-icon :icon="['fal', 'table']" class="text-3xl" />
          </div>
          <div>
            <div
              class="text-lg font-semibold col-span-full flex items-center space-x-2"
            >
              <div>Taster Conversions</div>
              <div
                class="transform default-transition group-hover:translate-x-3"
              >
                &rarr;
              </div>
            </div>
            <div class="text-gray-700">
              Generate a report to show which of the tasters convert best
            </div>
          </div>
        </router-link>
        <div class="text-lg font-semibold col-span-full">Retention</div>

        <div class="col-span-full sm:col-span-3">
          <ltv />
        </div>
        <trial-ratio
          class="col-span-full sm:col-span-1 h-full"
          label="Cancellations"
          helper="Cancellation Rate"
          stat="cancellationsPercentage"
          suffix="%"
          percentage
          :dataset="['corePlus', 'comparePlus']"
        />

        <!-- <div class="text-lg font-semibold col-span-full">
          Taster Boxes
        </div>

        <trial-ratio
          class="col-span-full sm:col-span-1"
          label="Taster Boxes Sent"
          stat="totaltrialboxes"
          helper="Total"
        />
        <trial-ratio
          class="col-span-full sm:col-span-1"
          label="New Subscribers"
          helper="Total"
          stat="newsubscribersMtd"
        />

        <trial-ratio
          class="col-span-full sm:col-span-1"
          label="Taster Box Conversions"
          stat="30Dayrollingtrialconversion"
          helper="Conversion Rate"
          suffix="%"
          percentage
        />
        <div class="text-lg font-semibold col-span-full px-2 pt-4 ">
          Monthly Subscriptions
        </div>
        <trial-ratio
          class="col-span-full sm:col-span-1"
          label="Total Subscribers"
          helper="Total"
          stat="totalSubscribers"
        />

        <trial-ratio
          class="col-span-full sm:col-span-1"
          label="Subscription Boxes Sent"
          stat="totalboxes"
          helper="Total"
        />

        <trial-ratio
          class="col-span-full sm:col-span-1"
          label="Cancelled Subscribers"
          helper="Total"
          stat="cancellationsMtd"
        /> -->

        <div class="text-lg font-semibold col-span-full px-2 pt-4">
          Last 6 months
        </div>

        <reporting-table class="col-span-full" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { subMonths, format } from 'date-fns';

import Ltv from '@/components/common/ui/Ltv.vue';
import TrialRatio from '@/components/common/ui/TrialRatio.vue';
import ReportingTable from '../../components/common/ui/ReportingTable.vue';
export default {
  components: {
    TrialRatio,
    Ltv,
    ReportingTable
  },
  name: 'ReportingRetentionStats',
  computed: {
    ...mapGetters({
      isAdmin: 'isAdmin',
      isCustomerService: 'isCustomerService'
    }),
    lastMonth() {
      return format(subMonths(new Date(), 1), 'MMM');
    },
    compareMonth() {
      return format(subMonths(new Date(), 2), 'MMM');
    }
  }
};
</script>

<style></style>
