<template>
  <div
    class="w-full relative flex flex-col sm:flex-row sm:space-x-12"
    :class="!isCamo ? 'border rounded-lg p-4' : ''"
  >
    <div
      class="flex flex-col items-stretch divide-y border-r min-w-max space-y-4 pr-4 w-64"
    >
      <trial-ratio is-camo label="LTV" helper="" stat="ltvcancel" percentage />
      <trial-ratio
        is-camo
        label="LTV New"
        helper=""
        stat="ltvadvanced"
        percentage
        class="pt-4"
      />
      <trial-ratio
        is-camo
        label="ARPU"
        helper=""
        stat="arpucancelled"
        currency
        class="pt-4"
      />
    </div>

    <div
      v-if="uniqMonths"
      class="flex h-64 flex-grow divide-x divide-gray-100 overflow-x-auto"
    >
      <div
        :key="`month-${index}`"
        v-for="(month, index) in uniqMonths"
        class="h-full flex flex-grow flex-col justify-center items-center"
      >
        <div class="w-full text-xs pb-2 text-gray-400 text-center">
          {{ month.month }}
        </div>

        <!-- <div
            class="absolute bottom-0   w-full bg-indigo-100"
            :style="{ height: `${getHeight(month.vals.previous)}%` }"
          ></div> -->
        <div
          class="text-xs py-1 text-gray-400 items-center justify-center text-center w-full space-x-1 flex-col h-8"
        >
          <div>({{ month.vals.previous || 0 }})</div>
        </div>
        <div class="flex-grow w-full">
          <div
            class="relative min-w-bar w-full bg-indigo-400"
            :style="{ height: `${getHeight(month.vals.today)}%` }"
          ></div>
          <div
            class="text-xs py-1 text-gray-500 items-center justify-center text-center w-full space-x-1 flex-col h-8"
          >
            <div>{{ month.vals.today }}</div>
          </div>
        </div>

        <div class="flex-grow flex justify-end items-end">
          <div
            v-html="getChange(month.vals.today, month.vals.previous, month)"
            v-if="month.vals.today && month.vals.previous"
            class="text-xs"
            :class="[
              month.vals.today > month.vals.previous
                ? 'text-green-400'
                : month.vals.today === month.vals.previous
                  ? 'text-gray-400'
                  : 'text-red-400'
            ]"
          ></div>
        </div>
      </div>
      <div
        class="h-full flex flex-col sticky bg-white z-20 relative right-0 justify-center italic text-left items-center text-xs text-gray-400"
      >
        <div class="w-20 pb-2 px-2">Month</div>
        <div class="py-1">Last Month</div>
        <div class="flex-grow"></div>
        <div>% Change</div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import utils from '@/utils';
import { groupBy, toArray, maxBy } from 'lodash/fp';
import { mapGetters } from 'vuex';
import TrialRatio from './TrialRatio.vue';
export default {
  components: { TrialRatio },
  name: 'Ltv',
  props: {
    isCamo: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      stats: {
        all: [],
        today: { date: null, values: null },
        previous: { date: null, values: null }
      }
    };
  },
  computed: {
    ...mapGetters({
      analyticsHistory: 'analyticsHistoryGetter'
    }),
    maxValue() {
      return maxBy(function (o) {
        return o;
      }, this.stats.all);
    },
    uniqMonths() {
      const all = [];
      const self = this;
      if (this.stats.today.values) {
        this.stats.today.values.forEach(t => {
          t.today = Number(t.count);
          self.stats.all.push(Number(t.count));
          all.push(t);
        });
      }
      if (this.stats.previous.values) {
        this.stats.previous.values.forEach(t => {
          t.previous = Number(t.count);
          self.stats.all.push(Number(t.count));
          all.push(t);
        });
      }

      const group = toArray(groupBy('monthsactive2', all));

      const vals = [];

      group.forEach(g => {
        vals.push({ month: g[0].monthsactive2, vals: { ...g[0], ...g[1] } });
      });

      return vals;
    }
  },
  methods: {
    getHistogramData(start, end, value) {
      this.$store
        .dispatch('getAnalyticsStats', {
          start: start,
          end: end,
          singular: true
        })
        .then(resp => {
          console.log('got it');
          this.stats[value].values = resp.data.content[0].data.histogram;
          this.stats[value].date = end;
        });
    },
    getHeight(val) {
      return utils.getPercentageOf(this.maxValue, val).toFixed();
    },
    getChange(today, previous) {
      const t = Number(today) || 0;
      const p = Number(previous) || 0;

      const diff = utils.getPercentageDiff(t, p).toFixed();

      if (t > p) {
        return `&uarr;${Math.abs(diff)}%`;
      } else if (p > t) {
        return `&darr;${Math.abs(diff)}%`;
      }

      return '-';
    }
  },
  mounted() {
    this.stats['today'].values = this.analyticsHistory?.core?.data?.histogram;
    this.stats['today'].date = moment().format('YYYY-MM-DD');

    this.stats['previous'].values =
      this.analyticsHistory?.compare?.data?.histogram;
    this.stats['previous'].date = moment();
  }
};
</script>

<style lang="scss" scoped>
.text-xxs {
  font-size: 0.6rem;
}

.min-w-bar {
  min-width: 70px;
}
</style>
