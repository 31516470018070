<template>
  <div class="w-full group">
    <div
      class="w-full space-y-4 h-full flex-col flex relative"
      :class="{
        'rounded-lg p-4 border': !isCamo
      }"
    >
      <div
        class="font-semibold default-transition text-lg items-center flex space-x-4"
        :class="{
          'opacity-5': showHelper,
          'opacity-100': !showHelper
        }"
      >
        <div class="flex flex-grow items-center space-x-2 h-6">
          <div class="truncate" v-if="label">{{ label }}</div>
          <span
            v-if="hasHelper"
            class="hidden -top-1 group-hover:flex cursor-pointer hover:text-gray-600 text-gray-500"
            @mouseover="showHelperAction"
            @mouseout="hideHelperAction"
          >
            <font-awesome-icon
              fixed-width
              class="text-sm"
              :icon="['fal', 'circle-info']"
          /></span>
        </div>
        <div
          v-if="statCompare.formatted"
          class="hidden text-base text-gray-400 font-normal group-hover:flex"
        >
          ({{ statCompare.formatted }})
        </div>
      </div>
      <div class="flex-grow flex flex-col" v-if="hasStat">
        <div
          class="default-transition"
          :class="{
            'opacity-5': showHelper,
            'opacity-100': !showHelper
          }"
        >
          <div class="text-3xl font-semibold flex space-x-2 items-center">
            <div>
              <span v-if="currency && statNow.formatted"
                >£{{ statNow.formatted || '-' }}</span
              >
              <span v-else>{{ statNow.formatted || '-' }}</span>

              <span class="text-lg" v-if="suffix && statNow.formatted">{{
                suffix
              }}</span>
            </div>
            <div class="text-xs" v-if="statChange">
              <div v-html="statChange"></div>
            </div>
          </div>
          <div class="text-gray-500 font-mono text-xs mt-1">
            {{ helper }}
          </div>
        </div>
        <transition
          enter-active-class="miniFadeInUp"
          leave-active-class="miniFadeOutDown"
          mode="out-in"
        >
          <div
            class="absolute bottom-2 right-2 left-2 p-2 pb-1 font-mono text-gray-600 rounded text-xs"
            v-if="showHelper"
          >
            {{ hasHelper.description }}
          </div>
        </transition>
      </div>
      <div v-else class="flex items-center flex-grow justify-center">
        <div class="p-1 text-sm bg-gray-50 italic text-gray-400 px-2 rounded">
          No data available
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { last, isUndefined } from 'lodash/fp';
import { mapGetters, mapActions } from 'vuex';
import utils from '@/utils';
export default {
  name: 'TrialRatio',
  data() {
    return {
      stats: { core: null, compare: null },
      last,
      showHelper: false,
      timeout: null
    };
  },
  props: {
    stat: {
      type: String
    },
    label: {
      type: String
    },
    helper: {
      type: String
    },
    fromToday: {
      type: Boolean,
      default: false
    },
    suffix: {
      type: String,
      default: ''
    },
    isCamo: {
      type: Boolean,
      default: false
    },
    percentage: {
      type: Boolean,
      default: false
    },
    dataset: {
      type: Array,
      default: () => ['core', 'compare']
    },
    currency: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    ...mapActions({
      getHistoricalAnalytics: 'getHistoricalAnalytics',
      getAnalyticsHelpers: 'getAnalyticsHelpers'
    }),
    showHelperAction() {
      this.timeout = setTimeout(() => {
        this.showHelper = true;
      }, 500);
    },
    hideHelperAction() {
      clearTimeout(this.timeout);
      this.showHelper = false;
    }
  },
  computed: {
    ...mapGetters({
      analyticsHistory: 'analyticsHistoryGetter',
      analyticsHelpers: 'analyticsHelpersGetter'
    }),
    hasStat() {
      return !isUndefined(this.analyticsHistory?.core?.data[this.stat]);
    },
    hasHelper() {
      return (
        this.analyticsHelpers[this.stat] || {
          description: 'No additional information available'
        }
      );
    },

    statChange() {
      if (this.statCompare.raw) {
        const isUp = utils.decimalPlaces(
          utils.getPercentageDiff(this.statNow.raw, this.statCompare.raw),
          1
        );

        const isDown = utils.decimalPlaces(
          utils.getPercentageDiff(this.statCompare.raw, this.statNow.raw),
          1
        );

        if (this.statNow.raw === this.statCompare.raw) {
          return `<span class="text-gray-400">- </span>`;
        } else {
          if (this.statNow.raw > this.statCompare.raw) {
            return `<span class="text-green-500">&uarr; ${isUp}% </span>`;
          } else {
            return `<span class="text-red-500">&darr; ${isDown}% </span>`;
          }
        }
      } else {
        return null;
      }
    },
    statNow() {
      if (this.hasStat) {
        return {
          raw: this.core?.data[this.stat],
          formatted: this.core?.data[this.stat].toLocaleString()
        };
      }
      return false;
    },
    statCompare() {
      if (this.hasStat) {
        return {
          raw: this.compare?.data[this.stat],
          formatted: this.compare?.data[this.stat]
            ? this.compare?.data[this.stat].toLocaleString()
            : null
        };
      }

      return false;
    },
    core() {
      return this.analyticsHistory[this.dataset[0]];
    },
    compare() {
      return this.analyticsHistory[this.dataset[1]];
    }
  },
  mounted() {
    if (!this.analyticsHistory) {
      this.getHistoricalAnalytics;
    }
    if (!this.analyticsHelpers) {
      this.getAnalyticsHelpers;
    }
  }
};
</script>

<style></style>
